var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($) {
  // @todo fix this sorting logic, sometimes it messes up.
  // We need to listen for the event outside Drupal since it get's triggered before
  // $(document).on('inv_status_display:updated', '.js-product', function(e) {
  //   var $item = $(this);
  //   var skuBaseId = $item.data('sku-base-id');
  //   var skuData = prodcat.data.getSku(skuBaseId);
  //   if (skuData.INVENTORY_STATUS && skuData.INVENTORY_STATUS === 7) {
  //     $(this).data('invSort', 1).attr('data-inv-sort', 1);
  //     $(this).trigger('inv_status_sort:update');
  //   }
  // });

  Drupal.behaviors.mppSortV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $mppContainer = $('.js-mpp-container', context);
      var $sortContainer = $('.js-mpp-grid-sortable', $mppContainer);
      var $sortSelect = $('.js-mpp-sort-menu', $mppContainer);
      var $html = $('html', context);
      var $controlsContainer = $('.js-mpp-container__controls', $mppContainer);
      var isCr22 = Drupal.settings.global_variables && Drupal.settings.global_variables.cr22;

      // initialize the selectBox for Sort custom dropdown
      $sortSelect.selectBox({ mobile: isCr22 ? true : false });
      
      var $sortDropdown = $('.js-mpp-sort-menu-selectBox-dropdown-menu', context);

      $sortSelect.on('change', function () {
        $(document).trigger('product:quickshopV2_destroy');
        // Sort By option reset the sort to default order, touts can be visible in this state
        // They will still be hidden if filters are active on grid
        if (!$('option:selected', this).attr('data-show-touts')) {
          $sortContainer.addClass('js-mpp-grid--sorted');
        } else {
          $sortContainer.removeClass('js-mpp-grid--sorted');
        }
        $sortContainer.mixItUp('sort', this.value);

        if (isCr22) {
          $(window).scrollTop(0);
        }
      });

      if (isCr22) {
        $sortSelect.on('open', function () {
          $controlsContainer[0].scrollIntoView();
          $html.addClass('mpp-container__overlay');
          $sortDropdown.css({ top: $mppContainer.offset().top });
        });

        $sortSelect.on('close', function () {
          $html.removeClass('mpp-container__overlay');
        });
      }

    },
    attached: false
  };
})(jQuery);
